declare global {
  interface Window {
    environment: IEnvironment;
  }
}

interface IEnvironment {
  name: "development" | "test" | "stage" | "production";
}

interface IConfig {
  COMPLIANCE_API_URL: string;
  SERVICE_LINE_CHECK_API_URL: string;
  CONNECT_API_URL: string;
  UPP_CONNECT_API_URL: string;
  envName?: string;
  locizeEnabled: boolean;
  debugMode: boolean;
  uppSearchUrl: string;
  myLoginUrl: string;
  uppSearchApiUrl: string;
}

const initConfig = (): IConfig => {
  const envName = window.environment?.name ?? "development"; // default provided for tests
  switch (envName) {
    case "development":
      return {
        COMPLIANCE_API_URL:
          "https://kyc-protect-api.compliance-test.creditsafe.com/api",
        SERVICE_LINE_CHECK_API_URL:
          "https://test-api.uppdev.creditsafe.com/ubo",
        CONNECT_API_URL: "https://connect.test.creditsafe.com",
        UPP_CONNECT_API_URL: "https://upp-connect.test.creditsafe.com",
        envName: "development",
        locizeEnabled: false,
        debugMode: true,
        uppSearchUrl: "https://upp-search-test.uppdev.creditsafe.com",
        uppSearchApiUrl: "https://test-api.uppdev.creditsafe.com",
        myLoginUrl: "https://mylogin.test.creditsafe.com",
      };
    case "test":
      return {
        COMPLIANCE_API_URL:
          "https://kyc-protect-api.compliance-test.creditsafe.com/api",
        SERVICE_LINE_CHECK_API_URL:
          "https://test-api.uppdev.creditsafe.com/ubo",
        CONNECT_API_URL: "https://connect.test.creditsafe.com",
        UPP_CONNECT_API_URL: "https://upp-connect.test.creditsafe.com",
        envName: "test",
        locizeEnabled: true,
        debugMode: true,
        uppSearchUrl: "https://upp-search-test.uppdev.creditsafe.com",
        uppSearchApiUrl: "https://test-api.uppdev.creditsafe.com",
        myLoginUrl: "https://mylogin.test.creditsafe.com",
      };
    case "stage":
      return {
        COMPLIANCE_API_URL:
          "https://kyc-protect-api.compliance-stage.creditsafe.com/api",
        SERVICE_LINE_CHECK_API_URL:
          "https://demo-api.uppdev.creditsafe.com/ubo",
        CONNECT_API_URL: "https://connect.stage.creditsafe.com",
        UPP_CONNECT_API_URL: "https://upp-connect.stage.creditsafe.com",
        envName: "stage",
        locizeEnabled: true,
        debugMode: false,
        uppSearchUrl: "https://upp-search-stage.uppdev.creditsafe.com",
        uppSearchApiUrl: "https://demo-api.uppdev.creditsafe.com",
        myLoginUrl: "https://mylogin-staged.creditsafe.com",
      };
    case "production":
      return {
        COMPLIANCE_API_URL: "https://kyc-protect-api.creditsafe.com/api",
        SERVICE_LINE_CHECK_API_URL: "https://api.app.creditsafe.com/ubo",
        CONNECT_API_URL: "https://connect.creditsafe.com",
        UPP_CONNECT_API_URL: "https://upp-connect.creditsafe.com",
        envName: "production",
        locizeEnabled: true,
        debugMode: false,
        uppSearchUrl: "https://upp-search.app.creditsafe.com",
        uppSearchApiUrl: "https://api.app.creditsafe.com",
        myLoginUrl: "https://mylogin.creditsafe.com",
      };
    default:
      return {
        COMPLIANCE_API_URL:
          "https://kyc-protect-api.compliance-test.creditsafe.com/api",
        SERVICE_LINE_CHECK_API_URL:
          "https://test-api.uppdev.creditsafe.com/ubo",
        CONNECT_API_URL: "https://connect.test.creditsafe.com",
        UPP_CONNECT_API_URL: "https://upp-connect.test.creditsafe.com",
        envName: "local",
        locizeEnabled: false,
        debugMode: false,
        uppSearchUrl: "https://upp-search-test.uppdev.creditsafe.com",
        uppSearchApiUrl: "  ",
        myLoginUrl: "https://mylogin.test.creditsafe.com",
      };
  }
};

const config = initConfig();

export default config;
